<!-- 复刻的官网版本 使用列表传入 -->

<template>
    <div class="chanpin scroll-container"
         @touchstart="handleTouchStart"
         @touchmove="handleTouchMove"
         @touchend="handleTouchEnd">
        <div class="product-slider-container">
            <!-- 图片容器 -->
            <div class="product-image-wrapper">
                <div class="product-image-list" ref="imageListRef"
                    :style="{ 
                        transform: `translateX(-${currentIndex * imageWidth - touchOffset}px)`,
                        transition: isTransitioning ? 'transform 0.3s ease-out' : 'none'
                    }">
                    <a v-for="(product, index) in products" 
                       :key="index"
                       class="product-card-wrapper" 
                       :href="product.link">
                        <img :src="product.imageUrl" 
                             :alt="product.title"
                             class="product-image">
                        <span class="product-card-title">{{ product.title }}</span>
                        <span class="product-card-desc">{{ product.description }}</span>
                    </a>
                </div>
            </div>

            <div v-if="products.length > visibleImages" class="product-arrow-container">
                <div class="product-arrow product-left-arrow" 
                     id="product-leftArrow" 
                     @click="handleLeftClick"
                     :class="{ disabled: currentIndex === 0 }">&#10094;</div>
                <div class="product-arrow product-right-arrow" 
                     id="product-rightArrow" 
                     @click="handleRightClick"
                     :class="{ disabled: currentIndex >= totalImages - visibleImages }">&#10095;</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineProps } from 'vue'

// 定义props
const props = defineProps({
    products: {
        type: Array,
        default: () => [],
        required: true
    },
    visibleImages: {
        type: Number,
        default: 4
    }
})

// 响应式状态
const currentIndex = ref(0)
const totalImages = ref(0)
const imageWidth = ref(0)
const imageListRef = ref(null)
const touchStart = ref(0)
const touchOffset = ref(0)
const isTransitioning = ref(false)

// 计算当前状态
const updateImageData = () => {
    totalImages.value = props.products.length

    // 计算单个图片的总宽度（包括间距）
    const imageElement = document.querySelector('.product-card-wrapper')
    if (imageElement) {
        imageWidth.value = imageElement.offsetWidth + 20 // 20px是gap值
    }
}

// 处理点击事件
const handleRightClick = () => {
    if (currentIndex.value < totalImages.value - props.visibleImages) {
        currentIndex.value++
    }
}

const handleLeftClick = () => {
    if (currentIndex.value > 0) {
        currentIndex.value--
    }
}

// 触摸开始事件处理
const handleTouchStart = (e) => {
    touchStart.value = e.touches[0].clientX
    touchOffset.value = 0
    isTransitioning.value = false
}

// 触摸移动事件处理
const handleTouchMove = (e) => {
    if (touchStart.value === 0) return
    
    const currentTouch = e.touches[0].clientX
    const diff = currentTouch - touchStart.value
    
    // 限制滑动范围
    if ((currentIndex.value === 0 && diff > 0) || 
        (currentIndex.value >= totalImages.value - props.visibleImages && diff < 0)) {
        touchOffset.value = diff * 0.2 // 添加阻尼效果
    } else {
        touchOffset.value = diff
    }
}

// 触摸结束事件处理
const handleTouchEnd = () => {
    isTransitioning.value = true
    
    if (Math.abs(touchOffset.value) > imageWidth.value * 0.3) {
        // 滑动超过30%时切换图片
        if (touchOffset.value > 0 && currentIndex.value > 0) {
            currentIndex.value--
        } else if (touchOffset.value < 0 && 
                   currentIndex.value < totalImages.value - props.visibleImages) {
            currentIndex.value++
        }
    }
    
    touchStart.value = 0
    touchOffset.value = 0
    
    // 重置过渡状态
    setTimeout(() => {
        isTransitioning.value = false
    }, 300)
}

// 生命周期钩子
onMounted(() => {
    updateImageData()
    window.addEventListener('resize', updateImageData)
})

// 清理事件监听
onBeforeUnmount(() => {
    window.removeEventListener('resize', updateImageData)
})
</script>

<style scoped>
.chanpin {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
}

.chanpin p {
    color: rgb(0, 0, 0);
    
    font-size: 64px;
    font-weight: bold;
    line-height: 84px;
    margin: 0;
}

.scroll-container {
    overflow: hidden;
    position: relative;
    height: 451px;
    padding-bottom: 12px;
}

.product-slider-container {
    position: relative;
    width: 100%;
    
}

.product-image-wrapper {
    width: 100%;
    overflow: hidden;
}

.product-image-list {
    display: flex;
    transition: transform 0.3s ease-in-out;
    gap: 20px;
    padding-left: 500px;
    touch-action: pan-y pinch-zoom; /* 优化触摸体验 */
}

.product-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product-card-title {
    
    color: black;
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
}

.product-card-desc {
    
    font-size: 14px;
    color: black;
    font-weight: 400;
    margin-top: 20px;
}

.product-image {
    width: 320px;
    height: 284px;
    object-fit: cover;
    border-radius: var(--cx-radius);
}

.product-arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.product-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    font-size: 20px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    border-radius: 50%;
    margin: 0 10px;
    z-index: 100;
}

.product-left-arrow {
    left: 0;
}

.product-right-arrow {
    right: 0;
}

.product-arrow.disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

/* 添加媒体查询 */
@media screen and (max-width: 768px) {
    .product-arrow-container {
        display: none;
    }
    
    .product-image-list {
        padding-left: 20px; /* 移动端减小左边距 */
    }
    
    .product-slider-container {
        width: 100%; /* 移动端全宽显示 */
    }
}
</style>