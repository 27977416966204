import { createI18n } from 'vue-i18n'
import zh from '@/locales/zh.json'
import en from '@/locales/en.json'

// 从 localStorage 获取保存的语言设置，如果没有则默认使用中文
const savedLocale = localStorage.getItem('locale') || 'zh'

const i18n = createI18n({
  legacy: false,
  locale: savedLocale, // 使用保存的语言设置
  fallbackLocale: 'zh',
  messages: {
    zh,
    en
  },
  escapeParameter: true
})

export default i18n